'use client';

import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import OptimizedImage from '@web/components/OptimizedImage';

export default function SellerImage({
  seller_product_url,
  width = 64,
  height = 40,
}: {
  seller_product_url: string;
  width?: number;
  height?: number;
}) {
  const [loadedImage, setLoadedImage] = useState(true);
  const [imageSrc, setImageSrc] = useState('');
  useEffect(() => {
    try {
      const url = !seller_product_url
        ? ''
        : seller_product_url?.includes('amazon.')
        ? 'amazon.com'
        : new URL(seller_product_url)?.host;
      setImageSrc(url);
    } catch (error) {
      console.log(error.toString(), seller_product_url);
    }
  }, [seller_product_url]);

  return (
    <>
      {imageSrc && loadedImage ? (
        <OptimizedImage
          src={`https://img.logo.dev/${imageSrc}?token=pk_BDaBCUfDT2GGgSbZ8cuM0A&size=64`}
          width={width}
          height={height}
          alt={imageSrc}
          style={{
            objectFit: 'contain',
          }}
          onError={() => setLoadedImage(false)}
        />
      ) : (
        <Box sx={{ width: width }} />
      )}
    </>
  );
}
