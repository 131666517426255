'use client';

import { usePathname, useRouter } from 'next/navigation';
import { useMemo } from 'react';
import { useCollections } from '@web/context/collections';
import { useUser } from '@auth0/nextjs-auth0/client';

const useProductCollection = (product) => {
  const { collections, addCollection, removeCollection } = useCollections();
  const { user, isLoading } = useUser();
  const router = useRouter();
  const pathname = usePathname();
  const isProductAdded = useMemo(() => {
    return collections.some((collection) => product.id === collection.pid);
  }, [collections, product.id]);

  const handleCollectionClick = async () => {
    if (!user && !isLoading) {
      await router.push(`/api/auth/login?returnTo=${pathname}`);
      return;
    }

    const body = {
      user_id: user?.sub,
      pid: product.id,
      email: user?.email,
      added: isProductAdded,
    };

    const response = await fetch('/api/collections', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      if (isProductAdded) {
        removeCollection(product.id);
      } else {
        addCollection({ pid: product.id });
      }
    }
  };

  return { isProductAdded, handleCollectionClick };
};

export default useProductCollection;
