'use client';

import Image from 'next/image';
import { useEffect, useState } from 'react';

const OptimizedImage = ({ src = '', alt = 'placeholder', ...props }) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [imgError, setImgError] = useState(false);

  useEffect(() => {
    setImgSrc(src);
    setImgError(false);
  }, [src]);

  const handleError = () => {
    if (!imgError) {
      setImgSrc('/assets/placeholder-image.webp');
      setImgError(true);
    }
  };

  return <Image onError={handleError} {...props} alt={alt} src={imgSrc} />;
};

export default OptimizedImage;
