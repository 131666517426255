export const currencyFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
});

export const numberFormatter = new Intl.NumberFormat('en-AU');
export const percentFormatter = new Intl.NumberFormat('en-AU', {
  style: 'percent',
});

export const dateFormatter = (options?: any) =>
  new Intl.DateTimeFormat('en-AU', options);

export const priceRange = ({ lowest_price, highest_price }) => {
  return (
    (lowest_price &&
      highest_price &&
      `${currencyFormatter.format(lowest_price)} - ${currencyFormatter.format(
        highest_price
      )}`) ||
    ''
  );
};
