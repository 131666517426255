import { Box } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';

const Rating = ({ value }) => {
  const stars = [1, 2, 3, 4, 5];

  return (
    <Box sx={{ display: 'flex', color: '#faaf00' }}>
      {stars.map((star) => (
        star <= value ?
          <StarIcon key={star} sx={{ fontSize: '1.25rem' }}/> :
          star - 0.5 === value ?
            <StarHalfIcon key={star} sx={{ fontSize: '1.25rem' }}/> :
            <StarBorderIcon key={star} sx={{ fontSize: '1.25rem' }}/>
      ))}
    </Box>
  );
};

export default Rating;
